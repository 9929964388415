import React, {useState, useEffect} from 'react';

export default function KiloTimer() {
  const [state, setState] = useState({
    version: 1,
    moment: 0,
    goalTime: 0,
    interval: 0,
    intervalHuman: "",
    calsToAdd: 0,
    pastAdditions: [],
  }); 

  useEffect(() => {
    const storage = window.localStorage;
    const nowTime = Date.now();
    if (storage.goalTime && storage.version === "1") {
      setState(prevState => ({
        ...prevState,
        goalTime: storage.goalTime,
        interval: storage.goalTime - nowTime,
		pastAdditions: storage.pastAdditions.split(",")
      }))
    } else {
      storage.setItem("goalTime", nowTime)
      storage.setItem("pastAdditions", [])
      storage.setItem("version", state.version)
      setState({ ...state, goalTime: nowTime })
    }
	
	if('serviceWorker' in navigator) {
      navigator.serviceWorker.register("dummyworker.js");
    };
  }, []);

  useEffect(
    () => {
      const nowTime = Date.now();
      const timerID = setInterval(
        () => setState(prevState => ({
          ...prevState,
          interval: prevState.goalTime - nowTime,
          intervalMinutes: msToHMS(prevState.goalTime - nowTime ),
        })), 1000
      );

      return () => {
        clearInterval(timerID);
      };
    }
  );


  function msToHMS( ms ) {
    var seconds = ms / 1000;
    var negative = false;
    var hours = parseInt( seconds / 3600 );  
    seconds = seconds % 3600;
    var minutes = parseInt( seconds / 60 );
    seconds = parseInt(seconds % 60);

    if (hours < 0 || minutes < 0 || seconds < 0) {
      negative = true;
      hours = Math.abs(hours);
      minutes = Math.abs(minutes);
      seconds = Math.abs(seconds);
    }

    hours = hours <= 9 ? "0" + hours : hours;
    minutes = minutes <= 9 ? "0" + minutes : minutes;
    seconds = seconds <= 9 ? "0" + seconds : seconds;
    return(negative
      ? "-" + hours + ":" + minutes + ":" + seconds
      : hours + ":" + minutes + ":" + seconds);
  }

  const onChange = (event) => {
    setState({ ...state, calsToAdd: event.target.value });
  }
  
  const reAdd = (event) => {
    setState({ ...state, calsToAdd: event.target.value });
	setGoal(event)
  }

  function setGoal(event) {
    event.preventDefault();
    const storage = window.localStorage;
	const safeEvalString = state.calsToAdd.replace(/[^-()\d*+.]/g, '');
    const calTimeSpan = eval(safeEvalString) / 2000 * 24 * 60 * 60 * 1000;
    const newGoalTime = Number(state.goalTime) + calTimeSpan;
    let newAdditions = state.pastAdditions.slice(0, 9);
	
	newAdditions.unshift(state.calsToAdd);
    setState({ ...state, goalTime: newGoalTime, pastAdditions: newAdditions});
    storage.setItem('goalTime', newGoalTime);
    storage.setItem('pastAdditions', newAdditions);
  }




  return (
    <div>
      <h1>
        {state.intervalMinutes}
      </h1>
      <form>
        <label> Add kCals: </label>
        <input
          value={state.calsToAdd}
          onChange={onChange}
          type="text"
        />
        <button type="submit" onClick={setGoal}>Add</button>
      </form>
      {state.pastAdditions.map((addition) => (
	    <input
		  value={addition}
		  type="button"
		  onClick={reAdd}
		  readOnly
		/>
	  ))}
    </div>
  );
}
