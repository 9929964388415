import './App.css';
import KiloTimer from './components/kiloTimer'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>KiloTime</h1>
      </header>
      <KiloTimer />
    </div>
  );
}

export default App;
